<template>
  <section class="label-title-wrap">
    <h2 class="label-title-h2">
      <span>{{num}}</span>
      {{title}}
    </h2>
    <div class="radioTitle" v-if="showTitle">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>请输入关于平台的简介说明（支持文字/图片，回收商户端小程序及门店端小程序将同时获取以下数据）</div>
    </div>
    <div class="radioTitle" v-if="showTitleUser">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>请输入用户服务协议（支持文字/图片，回收商户端小程序及门店端小程序将同时获取以下数据）</div>
    </div>
    <div class="radioTitle" v-if="showTitleProduct">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>济南繁星回收产品介绍可以为文字或图片，将在【门店端小程序】首页顶部点击'查看更多'页面显示</div>
    </div>
    <div class="radioTitle" v-if="showTitleAgree">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>‘如何获取及使用繁星码’可以为文字或图片，将在【门店端小程序】确认回收页面点击'如何获取'弹窗显示</div>
    </div>
    <div class="radioTitle" v-if="showTitleApply">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>该说明内容在门店商户申请开通支付页面返回。门店小程序端对应页面:[申请开通线上支付]</div>
    </div>
    <div class="radioTitle" v-if="showTitleOpen">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>该说明内容在门店端-回收商管理模块，【开启支付】详情页面点击’查看更多‘展示以下内容</div>
    </div>
    <div class="radioTitle" v-if="showTitlePayExplain">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>该说明内容在回收端-个人中心-查看账户-线上支付说明返回（前端点击'查看线上支付说明'查看内容）</div>
    </div>
    <div class="radioTitle" v-if="showTitleCashExplain">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>说明：该说明内容展示在门店端《济南繁星回收门店端提现规则》内容弹窗。</div>
    </div>
    <div class="radioTitle" v-if="showTitleAwardExplain">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>该说明内容展示在门店端《济南繁星回收奖励提现规则》内容页面</div>
    </div>
    <div class="radioTitle" v-if="showTitleLogisticsExplain">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>请输入关于繁星物流的海报内容或图片，将展示在回收端小程序申请物流功能页面。</div>
    </div>
    <div class="radioTitle" v-if="showTitleServeExplain">
      <div>
        <img src="@/assets/images/imgAll/ico_ts_red@2x.png" alt />
      </div>
      <div>请输入繁星物流服务协议</div>
    </div>
  </section>
</template>

<script>
// import { mapState } from 'vuex'

export default {
  props: ["num", "title","showTitle","showTitleUser","showTitleProduct", "showTitlePay", "showTitleAgree","showTitleApply","showTitleOpen",'showTitlePayExplain','showTitleCashExplain','showTitleAwardExplain','showTitleLogisticsExplain','showTitleServeExplain'],
  data() {
    return {};
  },

  created() {},

  methods: {},
  // computed: mapState(['http_response_success_code']),

  components: {}
};
</script>
<style lang="scss" scoped>
.label-title-wrap {
  height: 40px;
  display: flex;
  margin-bottom: 20px;
  .radioTitle {
    display: flex;
    margin-bottom: 15px;
    height: 40px;
	line-height: 40px;
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
    div:first-child {
      width: 16px;
      height: 16px;
      margin: 3px;
    }
  }
  .label-title-h2 {
    height: 40px;
    width: 188px;
    background: url("../../../../assets/images/zypz_ico_bq@2x.png") no-repeat;
    background-size: 188px 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #fff;
    span {
      // width:18px;
      // height: 18px;
      // border-radius: 50%;
      // background: #fff;
      color: #fff;
      margin: 0 10px;
      // text-align: center;
      // line-height: 18px;
      // font-size: 12px;
    }
  }
}
</style>
