<template>
  <div class="Resources">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_zypz_@2x.png" alt />
        </div>
        <div class="case-title_max">公众号配置</div>
      </div>
    </div>
    <div class="tab_click">
      <div class="tabRound">
        <div :class="clickTab == 1 ? 'left' : 'right'" @click="getItme(1)">自动回复配置</div>
        <div :class="clickTab == 2 ? 'left' : 'right'" @click="getItme(2)">公众号菜单配置</div>
      </div>
      <div v-if="this.clickTab == 1" class="input_phone">
        <LableTitle title='关注后自动回复' num='1' :showTitle="false"></LableTitle>
        <div class="form-item">
          <div style="font-size:14px;margin-right:12px;color:#666;width: 80px;">回复内容</div>
          <el-input type="textarea" :rows="3" placeholder="请输入回复内容，用户在关注公众号后自动发送该消息，最多可输入50个字" v-model="followResponse" size="small" maxlength="50"
            clearable>
          </el-input>
        </div>
        <LableTitle title='发送消息自动回复' num='2' :showTitle="false"></LableTitle>
        <div class="form-item">
          <div style="font-size:14px;margin-right:12px;color:#666;width: 80px;">回复内容</div>
          <el-input type="textarea" :rows="3" placeholder="请输入回复内容，用户在向公众号发送任意消息后，系统自动按填写内容回复，最多可输入50个字"
            v-model="sendResponse" maxlength="50"  clearable>
          </el-input>
        </div>
        <div style="padding-left:50px">
          <el-button @click="handerClose()" size="small" style="margin-top:20px">返回</el-button>
          <el-button @click="handerSure()" type="primary" size="small" style="margin-top:20px">提交</el-button>
        </div>
      </div>
      <div v-if="this.clickTab == 2">
        <!-- <ResourcesEditor></ResourcesEditor> -->
        <div style="width: 100%;height: 300px;line-height: 300px;text-align: center;font-size: 22px;">功能正在升级中，敬请期待!</div>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { formValidateMobileLandline } from "@/utils/form-validate";
import LableTitle from './component/label-title'
export default {
  components: { LableTitle },
  data() {
    return {
      clickTab: "1",
      followResponse: "",//关注后自动回复
      sendResponse: "",//发送消息自动回复
    };
  },
  created() {
    console.log(this.$route.query.faceCode);
    this.getItme(1)
  },
  methods: {
    getItme(index) {
      this.clickTab = index
      if (index === 1) {
        const vo = {
          faceCode: this.$route.query.faceCode,
          keys: [],
          module: 'other'
        }
        _api.getStaticResource(vo).then(res => {
          if (res.code === 1) {
            res.data.forEach(item => {
              if (item.sysKey == 'weixin_sub_reply') {
                this.followResponse = item.sysValue || ""
              } else if (item.sysKey == 'weixin_message_reply') {
                this.sendResponse = item.sysValue || ""
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    //提交
    handerSure() {
      if (!this.followResponse) {
        return this.$message.error('请输入关注后自动回复！');
      }
      if (!this.sendResponse) {
        return this.$message.error('请输入发送消息自动回复！');
      }
      const vo = {
        faceCode: this.$route.query.faceCode,
        modifySysStaticConfigVo: [{
          "key": "weixin_sub_reply",
          "value": this.followResponse
        }, {
          "key": "weixin_message_reply",
          "value": this.sendResponse
        }],
      }
      _api.updateStaticResource(vo).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
        }
      })
    },
    // 返回
    handerClose() {
      this.$router.push({ path: "/IndependentManagement/IndepManagement" })
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Resources {
  width: 100%;
  min-height: 800px;
  background: #ffffff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;

  .tabRound {
    cursor: pointer;
    margin-bottom: 40px;
    width: 280px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 16px;
    display: flex;

    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      background: #0981FF;
      border-radius: 16px;
    }

    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      border-radius: 16px;
    }
  }

  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .icon_title {
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .case-title_max {
        display: inline-block;
        width: 200px;
        height: 17px;
        font-size: 18px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
  }

  .tab_click {
    padding: 20px 0 50px 50px;

    .input_phone {
      /deep/ .el-input {
        width: 340px;
      }
    }
  }

  .form-item {
    width: 560px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: 50px;
    margin-bottom: 20px;
  }

  .form-item.img {
    display: block;
    justify-content: flex-start;
  }
}
</style>